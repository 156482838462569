var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      staticClass: "filter-form",
      model: {
        value: _vm.form,
        callback: function ($$v) {
          _vm.form = $$v
        },
        expression: "form",
      },
    },
    [
      _c(
        "div",
        { staticClass: "subtitle-1 font-weight-medium white--text mb-4" },
        [_vm._v("\n    Выберите место\n  ")]
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c("v-autocomplete", {
                staticClass: "filter-form__control",
                attrs: {
                  items: _vm.items,
                  disabled: _vm.places.length < 2,
                  "search-input": _vm.search,
                  loading: !_vm.places.length,
                  "item-text": "text",
                  "item-value": "value",
                  clearable: "",
                  dense: "",
                  dark: "",
                  flat: "",
                  "hide-no-data": "",
                  "hide-details": "",
                  "solo-inverted": "",
                  "return-object": "",
                  "no-filter": "",
                  "no-data-text": "Не найдено",
                },
                on: {
                  "update:searchInput": function ($event) {
                    _vm.search = $event
                  },
                  "update:search-input": function ($event) {
                    _vm.search = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function (data) {
                      return [
                        [
                          _c("v-list-item-content", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.getItemHtml(data.item.text)
                              ),
                            },
                          }),
                        ],
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.activePlace,
                  callback: function ($$v) {
                    _vm.activePlace = $$v
                  },
                  expression: "activePlace",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }