var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "legend", attrs: { dense: "" } },
    _vm._l(_vm.legendItems, function (item, i) {
      return _c(
        "div",
        { key: i, staticClass: "legend__item" },
        [
          _c(
            "div",
            { staticClass: "legend__item-label" },
            [
              _c("SymbolComponent", {
                staticClass: "mr-2",
                attrs: { paint: item.icon, geo: item.geo },
              }),
              _vm._v(" "),
              _c("span", {
                domProps: { textContent: _vm._s(_vm.capitalize(item.text)) },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-switch", {
            staticClass: "legend__item-switcher ml-3 mt-0",
            attrs: {
              value: item.text,
              inset: "",
              dense: "",
              "hide-details": "",
              color: "primary",
            },
            model: {
              value: _vm.activeTypes,
              callback: function ($$v) {
                _vm.activeTypes = $$v
              },
              expression: "activeTypes",
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }