import { render, staticRenderFns } from "./List.vue?vue&type=template&id=87fbdfe2&scoped=true&"
import script from "./List.vue?vue&type=script&lang=ts&"
export * from "./List.vue?vue&type=script&lang=ts&"
import style0 from "./List.vue?vue&type=style&index=0&id=87fbdfe2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87fbdfe2",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
installComponents(component, {VBtn,VList,VListItem,VListItemContent,VListItemGroup,VListItemSubtitle})


/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('87fbdfe2')) {
      api.createRecord('87fbdfe2', component.options)
    } else {
      api.reload('87fbdfe2', component.options)
    }
    module.hot.accept("./List.vue?vue&type=template&id=87fbdfe2&scoped=true&", function () {
      api.rerender('87fbdfe2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/List/List.vue"
export default component.exports