var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-list",
        { staticClass: "items-list", attrs: { dense: "" } },
        [
          _c(
            "v-list-item-group",
            {
              model: {
                value: _vm.active,
                callback: function ($$v) {
                  _vm.active = $$v
                },
                expression: "active",
              },
            },
            _vm._l(_vm.portion, function (item, i) {
              return _c(
                "v-list-item",
                {
                  key: i,
                  staticClass: "align-start",
                  on: {
                    click: function ($event) {
                      return _vm.setDetail(item.id1)
                    },
                  },
                },
                [
                  _c("SymbolComponent", {
                    staticClass: "mr-2 mt-2 list-item-icon",
                    attrs: {
                      paint: _vm.getItemPaint(item),
                      geo: item.geo || "point",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c("div", [_vm._v(_vm._s(item.name))]),
                      _vm._v(" "),
                      _c("v-list-item-subtitle", [_vm._v(_vm._s(item.type))]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.displayItems.length > _vm.portion.length
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: { click: _vm.addPortion },
                },
                [_vm._v("Показать ещё")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }