import { render, staticRenderFns } from "./About.vue?vue&type=template&id=5e667ffc&"
import script from "./About.vue?vue&type=script&lang=ts&"
export * from "./About.vue?vue&type=script&lang=ts&"
import style0 from "./About.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5e667ffc')) {
      api.createRecord('5e667ffc', component.options)
    } else {
      api.reload('5e667ffc', component.options)
    }
    module.hot.accept("./About.vue?vue&type=template&id=5e667ffc&", function () {
      api.rerender('5e667ffc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/NextGIS/About.vue"
export default component.exports