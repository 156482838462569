import { render, staticRenderFns } from "./Legend.vue?vue&type=template&id=2e90c8ef&scoped=true&"
import script from "./Legend.ts?vue&type=script&lang=ts&"
export * from "./Legend.ts?vue&type=script&lang=ts&"
import style0 from "./Legend.vue?vue&type=style&index=0&id=2e90c8ef&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e90c8ef",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VSwitch})


/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2e90c8ef')) {
      api.createRecord('2e90c8ef', component.options)
    } else {
      api.reload('2e90c8ef', component.options)
    }
    module.hot.accept("./Legend.vue?vue&type=template&id=2e90c8ef&scoped=true&", function () {
      api.rerender('2e90c8ef', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Legend/Legend.vue"
export default component.exports