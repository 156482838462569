var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "symbol-component-wrapper", style: _vm.style },
    [
      _c("span", {
        class: "symbol-component symbol-fill " + _vm.geo,
        style: _vm.fillStyle,
      }),
      _vm._v(" "),
      _c("span", {
        class: "symbol-component symbol-stroke " + _vm.geo,
        style: _vm.strokeStyle,
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }