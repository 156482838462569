var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-text-field", {
        staticClass: "pt-2 pb-2",
        attrs: {
          value: _vm.url,
          flat: "",
          dense: "",
          readonly: "",
          outlined: "",
          "hide-details": "",
        },
        on: { "click:append": _vm.copyUrl },
      }),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "share-network-list pt-2" },
        _vm._l(_vm.networks, function (n) {
          return _c(
            "ShareNetwork",
            {
              key: n.network,
              style: { backgroundColor: n.color },
              attrs: {
                network: n.network,
                url: _vm.url,
                title: _vm.title,
                description: _vm.description,
                quote: _vm.quote,
                hashtags: _vm.hashtags,
              },
            },
            [
              _c("v-icon", [_vm._v(_vm._s(n.icon))]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(n.name))]),
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "2000" },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function (ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "success", text: "" },
                        on: {
                          click: function ($event) {
                            _vm.snackbar = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v("\n        Закрыть\n      ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [_vm._v("\n    Ссылка скопирована в буфер обмена\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }