var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter-panel" },
    [
      _c("h5", { staticClass: "text--secondary mb-1" }, [
        _vm._v("СПЕЦИАЛЬНЫЕ ФИЛЬТРЫ"),
      ]),
      _vm._v(" "),
      _c("v-checkbox", {
        key: "allSpecialFilters",
        staticClass: "filter-panel__checkbox mt-0",
        attrs: {
          dense: "",
          "hide-details": "",
          label: "Все",
          indeterminate:
            _vm.specialFilters.length &&
            _vm.specialFilters.length < _vm.specialFilterItems.length,
        },
        model: {
          value: _vm.allSpecialFilters,
          callback: function ($$v) {
            _vm.allSpecialFilters = $$v
          },
          expression: "allSpecialFilters",
        },
      }),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _vm._l(_vm.specialFilterItems, function (v) {
        return _c("v-checkbox", {
          key: v.value,
          staticClass: "filter-panel__checkbox mt-0",
          attrs: {
            dense: "",
            "hide-details": "",
            label: v.text,
            value: v.value,
          },
          model: {
            value: _vm.specialFilters,
            callback: function ($$v) {
              _vm.specialFilters = $$v
            },
            expression: "specialFilters",
          },
        })
      }),
      _vm._v(" "),
      _c("h5", { staticClass: "text--secondary mt-5 mb-1" }, [
        _vm._v("ТИПЫ СЮЖЕТОВ"),
      ]),
      _vm._v(" "),
      _c("v-autocomplete", {
        attrs: {
          items: _vm.narrativeTypeItems,
          dense: "",
          outlined: "",
          chips: "",
          "item-text": "name",
          "item-value": "name",
          multiple: "",
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function (data) {
              return [
                _c(
                  "v-chip",
                  _vm._b(
                    {
                      staticClass: "filter-panel__chip primary--text my-1 mx-0",
                      attrs: {
                        small: "",
                        label: "",
                        "input-value": data.selected,
                        close: "",
                      },
                      on: {
                        click: data.select,
                        "click:close": function ($event) {
                          return _vm.removeNarrativeType(data.item)
                        },
                      },
                    },
                    "v-chip",
                    data.attrs,
                    false
                  ),
                  [
                    _c("span", { staticClass: "text-truncate" }, [
                      _vm._v(_vm._s(data.item.name)),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "item",
            fn: function (data) {
              return [
                [
                  _c("v-list-item-content", [
                    _vm._v(
                      "\n          " + _vm._s(data.item.name) + "\n        "
                    ),
                  ]),
                ],
              ]
            },
          },
        ]),
        model: {
          value: _vm.narrativeTypesSelected,
          callback: function ($$v) {
            _vm.narrativeTypesSelected = $$v
          },
          expression: "narrativeTypesSelected",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }