var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.detail && _vm.meta
    ? _c(
        "div",
        { staticClass: "detail" },
        [
          _c(
            "v-list",
            { staticClass: "pt-0", attrs: { dense: "" } },
            [
              _vm._l(_vm.properties, function (item) {
                return _c(
                  "v-list-item",
                  {
                    key: item.value,
                    staticClass: "detail-prop-list px-0 pb-0",
                  },
                  [
                    _c("v-list-item-content", [
                      _c("span", { staticClass: "caption text--secondary" }, [
                        _vm._v(_vm._s(item.text)),
                      ]),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "body-2",
                        domProps: { innerHTML: _vm._s(_vm.getText(item)) },
                      }),
                    ]),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm.photos && _vm.photos.length
                ? _c(
                    "v-list-item",
                    { staticClass: "detail-prop-list px-0 pb-2" },
                    [
                      _vm.photos && _vm.photos.length
                        ? _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-subtitle", [_vm._v("Фото")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "photo-containerr" },
                                _vm._l(_vm.photos, function (photo, i) {
                                  return _c(
                                    "a",
                                    {
                                      key: photo.id,
                                      on: {
                                        click: function ($event) {
                                          _vm.dialog = true
                                          _vm.selectedPhoto = i
                                        },
                                      },
                                    },
                                    [
                                      _c("v-img", {
                                        staticClass: "grey lighten-2 mt-2",
                                        attrs: { src: photo.link_small },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-list-item",
                { staticClass: "detail-prop-list px-0 pb-2" },
                [
                  _vm.id
                    ? _c("Comments", { key: _vm.id, attrs: { id: _vm.id } })
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              staticClass: "ma-0 pa-3",
              attrs: { fullscreen: "", "content-class": "photo-dialog" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-carousel",
                {
                  attrs: {
                    height: "75vh",
                    "hide-delimiters": "",
                    "show-arrows": _vm.photos.length > 1,
                  },
                  model: {
                    value: _vm.selectedPhoto,
                    callback: function ($$v) {
                      _vm.selectedPhoto = $$v
                    },
                    expression: "selectedPhoto",
                  },
                },
                _vm._l(_vm.photos, function (photo, k) {
                  return _c(
                    "v-carousel-item",
                    { key: k },
                    [
                      _c("v-img", {
                        attrs: {
                          src: photo.link_big,
                          height: "100%",
                          contain: "",
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "close-img-dialog-btn",
                  attrs: { icon: "", text: "", dark: "", small: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.svg.close))])],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }