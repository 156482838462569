var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.items,
          search: _vm.search,
          "items-per-page": _vm.itemsPerPage,
          "hide-default-footer": "",
        },
        scopedSlots: _vm._u([
          {
            key: "headerCell",
            fn: function (props) {
              return [
                _c("v-tooltip", { attrs: { bottom: "" } }, [
                  _c(
                    "span",
                    { attrs: { slot: "activator" }, slot: "activator" },
                    [_vm._v(_vm._s(props.header.text))]
                  ),
                ]),
              ]
            },
          },
          {
            key: "items",
            fn: function (props) {
              return _vm._l(_vm.headers, function (h) {
                return _c("td", { key: h.value }, [
                  props.item[h.value] && props.item[h.value].length < 40
                    ? _c("span", [_vm._v(_vm._s(props.item[h.value]))])
                    : _c(
                        "span",
                        [
                          _c("show-more-field", {
                            attrs: { text: props.item[h.value], charCount: 40 },
                          }),
                        ],
                        1
                      ),
                ])
              })
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }