var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drawer-container" },
    [
      _vm.hasHeaderSlot
        ? _c(
            "div",
            { staticClass: "drawer-titlebar" },
            [
              _c(
                "v-toolbar",
                [
                  _c(
                    "v-toolbar-title",
                    { staticClass: "drawer-title" },
                    [_vm._t("header")],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "drawer-content" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _vm.hasFooterSlot ? _c("v-footer", [_vm._t("footer")], 2) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }